import React from 'react';
import { graphql } from 'gatsby';
import Header from '../components/Header';
import SEO from '../components/seo';
import Hero404 from '../components/Hero/variants/Hero404';
import Layout from '../components/layout';
import GeneralHead from '../components/generalHead';

const NotFoundPage = () => (
  <Layout forceShowFooter>
    <Header />
    <Hero404 />
  </Layout>
);

export const Head = ({
  data: {
    notFound: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export const query = graphql`
  query NotFoundQuery {
    notFound: datoCmsNotFound {
      indexable
      headHtml
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      schemaMarkup {
        id
        schema
      }
    }
  }
`;

export default NotFoundPage;
